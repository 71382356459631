<template>
  <transition name="fade">
    <form @keydown="form.onKeydown($event)" @submit.prevent="editTask" v-if="onProgress === false">
      <div class="row">
        <div class="col-12">

          <div class="row">
            <div class="col-lg-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('type_id') }">
              <div class="form-group">
                <label class="font-medium font-small">
                  Состояние
                </label>
                <multiselect :allow-empty="false"
                             :options="task_type_states"
                             :searchable="false"
                             @input="() => {
                               form.type_state_id = task_type_state ? task_type_state.id : null;
                             }"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Состояние задачи"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Состояние задачи"
                             track-by="id"
                             required
                             v-model="task_type_state">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="type_id"/>
              </div>
            </div>

            <div class="col-lg-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('type_status_id') }">
              <div class="form-group">
                <label class="font-medium font-small">
                  Статус
                </label>
                <multiselect :allow-empty="false"
                             :options="task_type_statuses"
                             :searchable="false"
                             @input="() => {
                               form.type_status_id = task_type_status ? task_type_status.id : null;
                             }"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Статус задачи"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Статус задачи"
                             track-by="id"
                             required
                             v-model="task_type_status">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="type_status_id"/>
              </div>
            </div>
          </div>

          <div class="row" v-if="typeof form.details.is_official_employment !== 'undefined' ||
                  typeof form.details.credit_terms_year !== 'undefined' ||
                  typeof form.details.initial_fee_percent !== 'undefined'">
            <div class="col-xl-4 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('details.is_official_employment') }">
              <div class="form-group mt-2">
                <label>
                  Оф. трудоустройство
                  <input class="form-check-input margin-l-5" type="checkbox"
                         v-model="form.details.is_official_employment">
                  <HasError :form="form" field="details.is_official_employment"/>
                </label>
              </div>
            </div>
            <div class="col-xl-4 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('details.credit_terms_year') }">
              <div class="form-group">
                <label>
                  <select class="form-control" v-model="form.details.credit_terms_year">
                    <option :value="null" class="text-muted">
                      Срок кредита, лет
                    </option>
                    <option :value="year"
                            v-for="year in [1, 2, 3, 4, 5]"
                            :selected="form.details.credit_terms_year === year">
                      {{ year }}
                    </option>
                  </select>
                  <HasError :form="form" field="details.credit_terms_year"/>
                </label>
              </div>
            </div>
            <div class="col-xl-4 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('details.initial_fee_percent') }">
              <div class="form-group">
                <label>
                  Аванс <strong>{{ form.details.initial_fee_percent }}</strong>%
                  <input type="range" class="form-range" min="25" max="60" step="1"
                         v-model="form.details.initial_fee_percent">
                  <HasError :form="form" field="details.initial_fee_percent"/>
                </label>
              </div>
            </div>
          </div>

          <div class="row" v-if="typeof form.details.mark !== 'undefined' ||
                  typeof form.details.model !== 'undefined'">
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('details.mark') }">
              <div class="form-group">
                <label>
                  <multiselect :allow-empty="true"
                               :options="marks"
                               :searchable="true"
                               @input="() => {
                                 form.details.mark = mark ? mark : null;
                               }"
                               @select="getCarModels"
                               deselect-label="Убрать"
                               label="name"
                               placeholder="Марка"
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="Марка"
                               track-by="id"
                               v-model="mark">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="details.mark"/>
                </label>
              </div>
            </div>
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('details.model') }">
              <div class="form-group">
                <label>
                  <multiselect :allow-empty="true"
                               :disabled="models.length < 1"
                               :options="models"
                               :searchable="true"
                               @input="() => {
                                 form.details.model = model ? model : null;
                               }"
                               deselect-label="Убрать"
                               label="name"
                               placeholder="Модель"
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="Модель"
                               track-by="id"
                               v-model="model">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="details.model"/>
                </label>
              </div>
            </div>
          </div>

          <div class="row" v-if="typeof form.details.year !== 'undefined' || typeof form.details.race !== 'undefined' ||
                  typeof form.details.engine !== 'undefined' || typeof form.details.link !== 'undefined'">
            <div class="col-xl-4 col-md-12" v-if="typeof form.details.year !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.year') }">
              <div class="form-group">
                <label>
                  <input :max="2023"
                         :min="1900"
                         class="form-control"
                         name="year"
                         placeholder="Год выпуска"
                         title="Год выпуска"
                         type="number"
                         v-model="form.details.year">
                  <HasError :form="form" field="details.year"/>
                </label>
              </div>
            </div>
            <div class="col-xl-4 col-md-12" v-if="typeof form.details.race !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.race') }">
              <div class="form-group">
                <label>
                  <input :max="9999999"
                         :min="1"
                         class="form-control"
                         name="race"
                         placeholder="Пробег"
                         title="Пробег"
                         type="number"
                         v-model="form.details.race">
                  <HasError :form="form" field="details.race"/>
                </label>
              </div>
            </div>
            <div class="col-xl-4 col-md-12" v-if="typeof form.details.engine !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.engine') }">
              <div class="form-group">
                <label>
                  <input :min="0.1"
                         class="form-control"
                         name="engine"
                         placeholder="Объем двигателя"
                         step=".1"
                         title="Объем двигателя"
                         type="number"
                         v-model="form.details.engine">
                  <HasError :form="form" field="details.engine"/>
                </label>
              </div>
            </div>
            <div class="col-xl-4 col-md-12" v-if="typeof form.details.link !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.link') }">
              <div class="form-group">
                <label>
                  <input class="form-control"
                         name="link"
                         placeholder="Ссылка на авто"
                         title="Ссылка на авто"
                         type="text"
                         v-model="form.details.link">
                  <HasError :form="form" field="details.link"/>
                </label>
              </div>
            </div>
          </div>

          <div class="row" v-if="typeof form.details.year_from !== 'undefined' ||
                  typeof form.details.year_to !== 'undefined' || typeof form.details.race_from !== 'undefined' ||
                  typeof form.details.race_to !== 'undefined'">
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.year_from !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.year_from') }">
              <div class="form-group">
                <label>
                  <input :max="2023"
                         :min="1900"
                         class="form-control"
                         name="year_from"
                         placeholder="Год, от"
                         title="Год, от"
                         type="number"
                         v-model="form.details.year_from">
                  <HasError :form="form" field="details.year_from"/>
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.year_to !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.year_to') }">
              <div class="form-group">
                <label>
                  <input :max="2023"
                         :min="1900"
                         class="form-control"
                         name="year_to"
                         placeholder="Год, до"
                         title="Год, до"
                         type="number"
                         v-model="form.details.year_to">
                  <HasError :form="form" field="details.year_to"/>
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.race_from !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.race_from') }">
              <div class="form-group">
                <label>
                  <input :max="9999999"
                         :min="1"
                         class="form-control"
                         name="race_from"
                         placeholder="Пробег, от"
                         title="Пробег, от"
                         type="number"
                         v-model="form.details.race_from">
                  <HasError :form="form" field="details.race_from"/>
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.race_to !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.race_to') }">
              <div class="form-group">
                <label>
                  <input :max="9999999"
                         :min="1"
                         class="form-control"
                         name="race_to"
                         placeholder="Пробег, до"
                         title="Пробег, до"
                         type="number"
                         v-model="form.details.race_to">
                  <HasError :form="form" field="details.race_to"/>
                </label>
              </div>
            </div>
          </div>

          <div class="row" v-if="typeof form.details.color !== 'undefined' ||
                  typeof form.details.gearbox !== 'undefined' || typeof form.details.transmission !== 'undefined' ||
                  typeof form.details.price_from !== 'undefined' || typeof form.details.price_to !== 'undefined'">
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.color !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.color') }">
              <div class="form-group">
                <label>
                  <multiselect :allow-empty="true"
                               :options="colors"
                               :searchable="true"
                               @input="() => {
                                 form.details.color = color ? color : null;
                               }"
                               deselect-label="Убрать"
                               label="title"
                               placeholder="Цвет"
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="Цвет"
                               track-by="id"
                               v-model="color">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="details.color"/>
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.gearbox !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.gearbox') }">
              <div class="form-group">
                <label>
                  <multiselect :allow-empty="false"
                               :options="gearboxes"
                               :searchable="true"
                               @input="() => {
                                 form.details.gearbox = gearbox ? gearbox : null;
                               }"
                               deselect-label="Убрать"
                               label="title"
                               placeholder="КПП"
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="КПП"
                               track-by="id"
                               v-model="gearbox">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="details.gearbox"/>
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.transmission !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.transmission') }">
              <div class="form-group">
                <label>
                  <multiselect :allow-empty="false"
                               :options="transmissions"
                               :searchable="true"
                               @input="() => {
                               form.details.transmission = transmission ? transmission : null;
                             }"
                               deselect-label="Убрать"
                               label="title"
                               placeholder="Привод"
                               select-label="Выбрать"
                               selected-label="Выбрано"
                               title="Привод"
                               track-by="id"
                               v-model="transmission">
                    <template slot="noResult">Не найдено</template>
                    <template slot="noOptions">Данные отсутствуют</template>
                  </multiselect>
                  <HasError :form="form" field="details.transmission"/>
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.price_from !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.price_from') }">
              <div class="form-group">
                <label>
                  <input :max="999999999"
                         :min="1"
                         class="form-control"
                         name="price_from"
                         placeholder="Цена, от"
                         title="Цена, от"
                         type="number"
                         v-model="form.details.price_from">
                  <HasError :form="form" field="details.price_from"/>
                </label>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-md-12" v-if="typeof form.details.price_to !== 'undefined'"
                 v-bind:class="{ 'has-error': form.errors.has('details.price_to') }">
              <div class="form-group">
                <label>
                  <input :max="999999999"
                         :min="1"
                         class="form-control"
                         name="price_to"
                         placeholder="Цена, до"
                         title="Цена, до"
                         type="number"
                         v-model="form.details.price_to">
                  <HasError :form="form" field="details.price_to"/>
                </label>
              </div>
            </div>
          </div>

          <div class="row" v-if="task.type_id === 30 &&
            ((task.type_state_id === 304 && form.type_state_id === 305) ||
            (task.type_state_id === 307 && form.type_state_id === 308))
          ">
            <hr/>

            <div class="col-xl-6 col-md-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('car_seller_new_price') }">
              <div class="form-group">
                  <label class="font-medium font-small">
                    Новая стоимость авто
                  </label>
                  <input :max="999999999"
                         :min="1"
                         class="form-control"
                         name="car_seller_new_price"
                         placeholder="Стоимость, $"
                         title="Стоимость, $"
                         type="number"
                         v-model="form.car_seller_new_price"
                         required>
                  <HasError :form="form" field="car_seller_new_price"/>
              </div>
            </div>

            <hr/>
          </div>

          <div class="row" v-if="typeof form.details.bargain_sum !== 'undefined'">
            <div class="col-xl-3 col-md-6 col-md-12"
                 v-bind:class="{ 'has-error': form.errors.has('details.bargain_sum') }">
              <div class="form-group">
                <label>
                  <input :max="999999999"
                         :min="1"
                         class="form-control"
                         name="bargain_sum"
                         placeholder="Сумма торга"
                         title="Сумма торга"
                         type="number"
                         v-model="form.details.bargain_sum">
                  <HasError :form="form" field="details.bargain_sum"/>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group" v-bind:class="{ 'has-error': form.errors.has('comment') }">
              <textarea class="form-control"
                        maxlength="3000"
                        id="comment"
                        rows="3"
                        :required="this.task.type_state_id !== this.form.type_state_id"
                        placeholder="Комментарий"
                        v-model="form.comment">
              </textarea>
            <HasError :form="form" field="comment"/>
          </div>

          <hr/>

          <div class="row">
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('term_at') }">
              <div class="form-group">
                <label for="term_at" class="font-medium font-small">
                  Срок выполнения
                </label>
                <v-date-picker
                    :masks="date_options.masks"
                    :max-date="date_options.maxDate"
                    :min-date="date_options.minDate"
                    :mode="'date'"
                    :model-config="date_options.formatDate"
                    :update-on-input="false"
                    color="blue"
                    locale="ru"
                    v-model="form.term_at"
                    trim-weeks>
                  <template v-slot="{inputValue, inputEvents}">
                    <input :value="inputValue"
                           class="form-control"
                           id="term_at"
                           placeholder="Дата выполнения"
                           v-on="inputEvents"/>
                  </template>
                </v-date-picker>
                <HasError :form="form" field="term_at"/>
              </div>
            </div>
            <div class="col-xl-6 col-md-12" v-bind:class="{ 'has-error': form.errors.has('term_time') }">
              <div class="form-group">
                <label for="term_time" class="font-medium font-small">
                  Время
                </label>
                <input type="time" class="form-control" id="term_time" name="term_time" v-model="form.term_time"
                       required>
                <HasError :form="form" field="term_time"/>
              </div>
            </div>
          </div>

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

          <br/>

          <Button :disabled="!validateData || form.busy"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-success pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-save"></i>
          </Button>
        </div>
      </div>
    </form>
    <loading-processing v-else></loading-processing>
  </transition>
</template>

<script>
import Vue from "vue";
import Form from "vform";
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import VCalendar from 'v-calendar';

Form.axios = API.apiClient;

Vue.use(VCalendar, {});

moment.locale('ru');

const DATE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'edit-task-state-form',

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  props: {
    task: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: new Form({
      details: {},
      comment: null,
      term_at: null,
      term_time: '00:00',
      is_updated_task: true,
    }),

    task_type_states: [],
    task_type_state: null,

    task_type_statuses: [],
    task_type_status: null,

    marks: [],
    mark: null,
    models: [],
    model: null,
    colors: [],
    color: null,
    gearboxes: [],
    gearbox: null,
    transmissions: [],
    transmission: null,

    onProgress: true,

    date_options: {
      minDate: moment().format(DATE_FORMAT),
      maxDate: moment().add(3, 'M').format(DATE_FORMAT),
      formatDate: {
        type: 'string',
        mask: DATE_FORMAT,
      },
      masks: {
        input: DATE_FORMAT,
      },
    },
  }),

  async created() {
    try {
      this.task_type = this.task.type;
      this.form.type_id = this.task_type.id;

      this.task_type_states = this.task.type.states;
      this.task_type_state = this.task.type_state;
      this.form.type_state_id = this.task_type_state.id;

      this.task_type_statuses = this.task.type.statuses.flow;
      this.task_type_status = this.task.type_status;
      this.form.type_status_id = this.task_type_status.id;

      this.form.details = this.task.details;

      if (this.form.details) {
        if (this.form.details.mark) {
          await this.getCarMarks().then(() => {
            if (this.task.details.mark) {
              this.mark = typeof this.task.details.mark === 'object' ?
                this.task.details.mark :
                JSON.parse(this.task.details.mark);

              this.getCarModels(this.mark).then(() => {
                if (this.form.details.model && this.task.details.model) {
                  this.model = typeof this.task.details.model === 'object' ?
                    this.task.details.model :
                    JSON.parse(this.task.details.model);
                }
              });
            }
          });
        }
        if (this.form.details.color) {
          await this.getCarColors().then(() => {
            if (this.task.details.color) {
              this.color = typeof this.task.details.color === 'object' ?
                this.task.details.color :
                JSON.parse(this.task.details.color);
            }
          });
        }
        if (this.form.details.gearbox) {
          await this.getCarGearboxes().then(() => {
            if (this.task.details.gearbox) {
              this.gearbox = typeof this.task.details.gearbox === 'object' ?
                this.task.details.gearbox :
                JSON.parse(this.task.details.gearbox);
            }
          });
        }
        if (this.form.details.transmission) {
          await this.getCarTransmissions().then(() => {
            if (this.task.details.transmission) {
              this.transmission = typeof this.task.details.transmission === 'object' ?
                this.task.details.transmission :
                JSON.parse(this.task.details.transmission);
            }
          });
        }
      }

      if (this.task.term_at) {
        this.form.term_at = this.task.term_at;
        this.form.term_time = this.task.term_time;
      }
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.form.type_id && this.form.type_state_id && this.form.type_status_id;
    },
  },

  methods: {
    async getCarMarks() {
      try {
        const marks_response = await API.apiClient.get('/cars/marks', {
          params: {
            is_paginated: 0,
            sorting: 'asc',
            sort_column: 'name'
          }
        });
        this.marks = marks_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarModels(mark) {
      try {
        this.models = [];
        const models_response = await API.apiClient.get(
            '/cars/mark/' + mark.id + '/models', {
              params: {
                is_paginated: 0,
                sorting: 'asc',
                sort_column: 'name',
              }
            }
        );
        this.models = models_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarColors() {
      try {
        const colors_response = await API.apiClient.get('/cars/colors');
        this.colors = colors_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarGearboxes() {
      try {
        const gearboxes_response = await API.apiClient.get('/cars/gearboxes');
        this.gearboxes = gearboxes_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getCarTransmissions() {
      try {
        const transmissions_response = await API.apiClient.get('/cars/transmissions');
        this.transmissions = transmissions_response.data;
      } catch (error) {
        errorHandler(error);
      }
    },

    async editTask() {
      if (this.form.term_at) {
        this.form.term_at = moment(this.form.term_at).format(DATE_FORMAT);
      }

      await this.form.put('/tasks/edit/' + this.task.id).then(() => {
        showSuccess();
        this.EventBus.$emit('hide-edit-task-modal');
        this.EventBus.$emit('task-items-reload');
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style>
.multiselect__content-wrapper {
  position: relative;
}
</style>
